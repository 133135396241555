import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

import SarvarLogo from "../images/sarvar-logo-black.svg"

function Footer() {
  const { t } = useTranslation()
  const context = React.useContext(I18nextContext)

  return (
    <>
      {context.language === 'hu' &&
      <div class="testimonial">
        <div className="testimonial__container">
          <div className="testimonial__inner">
            <h3 class="testimonial__title">Ne csak “zöld”, hanem egészségtudatos is legyen a vakáció!</h3>
            <p>Ha Sárvárra jössz kikapcsolódni, feltétlenül látogass el a csónakázó tavak környezetében lévő sport és rekreációs övezetbe! Legyen szó bármilyen évszakról, itt garantált a turisztikai élmény.</p>
            <a href="https://sarvar.hu/ne-csak-zold-hanem-egeszsegtudatos-is-legyen-a-vakacio/">További részletek a legújabb sárvári attrakciókról a kattintás után.</a>
          </div>
        </div>
      </div>}
      <footer className="footer">
        <div className="footer__container">
          <div className="footer__inner">
            <a className="footer__logo" href="home.html">
              <SarvarLogo />
            </a>
            <nav aria-label="Navigáció">
              <ul className="footer__navigation">
                <li>
                  <a href="https://sarvar.hu">sarvar.hu</a>
                </li>
                <li>
                  <a href="mailto:sarvar@tourinform.hu">sarvar@tourinform.hu</a>
                </li>
              </ul>
            </nav>
            <p className="footer__copyright">{t("footer-copyright")}</p>
          </div>
        </div>
      </footer>
      <img src="/2020.png" alt="Széchenyi 2020" className="szechenyi-2020"/> 
    </>
  )
}

export default Footer
