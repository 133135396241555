import React from "react"

function Header(props) {
  return (
    <section className="hero">
      <div className="hero__container">
        <div className="hero__inner">
          <div className="hero__caption">
            {props.children} 
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header
