import React from "react"

import Footer from "./footer"
import { Helmet } from "react-helmet"

import "../styles/main.scss"

function Layout(props) {
  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Knewave&family=Titillium+Web:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      {props.children}
      <Footer/>
    </>
  )
}

export default Layout