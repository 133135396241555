import React from "react"
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

import SarvarLogo from "../images/sarvar-logo-white.svg"
import Instagram from "../images/icon/instagram.svg"
import FB from "../images/icon/fb.svg"
import ArrowRight from "../images/icon/arrow-right.svg"

function Header() {
  const { languages } = useI18next()
  const { t } = useTranslation()

  return (
    <>
      <a className="skip-link" href="#content">{t("skip-link")}</a>
      <header className="js-header header">
        <div className="header__container">
          <div className="header__inner">
            <div className="header__left">
              <Link to="/" className="header__logo">
                <SarvarLogo />
              </Link>
              <ul className="header__lang-switcher">
                { languages.map((lng) => (
                  <li key={lng}>
                    <Link to={'/'} language={lng}>
                      {lng}
                    </Link>
                  </li>
                )) }
              </ul>
            </div>
            <div className="header__right">
              <a className="more-link" href="https://sarvar.hu" target="_blank" rel="noopener"> sarvar.hu
                <ArrowRight />
              </a>
              <div className="header__socials">
                <a className="header__social" href="https://www.facebook.com/sarvartdm/" aria-label="Facebook" target="_blank" rel="noopener">
                  <FB />
                </a>
                <a className="header__social" href="https://www.instagram.com/sarvartdm/" aria-label="Instagram" target="_blank" rel="noopener">
                  <Instagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
