import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Generate from "../images/icon/generate.svg"
import { I18nextContext } from 'gatsby-plugin-react-i18next'

function GenerateButton(props) {
  const context = React.useContext(I18nextContext);

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {fields: {collection: {eq: "tips"}}}) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              lang
            }
          }
        }
      }
    }
  `)

  let edges = data.allMarkdownRemark.edges;
  
  if(props.currentSlug) {
    edges = edges.filter(function(value, index, arr){
      return edges[index].node.fields.slug !== props.currentSlug;
    });
  }

  if(context.language) {
    edges = edges.filter(function(value, index, arr){
      return edges[index].node.frontmatter.lang === context.language;
    });
  }

  let urlPrefix = context.language !== 'hu' ? `/${context.language}/tips/` : '/tips/';

  let randomTipURL = urlPrefix + edges[Math.floor(Math.random() * edges.length)].node.fields.slug;

  return (
    <Link to={randomTipURL} className="hero__btn btn btn--primary btn--icon">
      <Generate />
      {props.children}
    </Link>
  )
}

export default GenerateButton
